
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  JobModule
} from '../../../../store/JobModule'
import SummaryService from '@/views/job/services/summary-service'
import Status from '@/components/Status/index.vue'
  @Component({
    name: 'JobDispatch',
    components: {
      Status
    }
  })
export default class JobDispatch extends Vue {
    protected isTableBusy = false
    protected notes: any = []
    protected fields: any = [{
      key: 'collapse',
      label: '',
      thStyle: {
        width: '2%'
      },
      class: 'text-center'
    },
    {
      key: 'vehicle_type',
      label: 'Vehicle Type',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Vehicle Capacity',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'driver',
      label: 'Driver',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '8%'
      }
    }
    ]

    created () {
      this.boot()
    }

    private boot () {
      this.populateJobDispatch()
    }

    protected async populateJobDispatch () {
      this.isTableBusy = true
      const response = await SummaryService.getDispatchDetails(JobModule.job.id)
      this.isTableBusy = false
      this.notes = response.data.map(note => ({
        ...note,
        lines: note.lines,
        is_send_to_mepa: note.is_send_to_mepa === 1,
        is_mepa_verified: note.is_mepa_verified === 1,
        is_send_to_customs: note.is_send_to_customs === 1,
        is_custom_verified: note.is_custom_verified === 1,
        is_gate_out: note.is_gate_out === 1,
        total_tonnage: note.lines.reduce((total, line) => { return total + parseFloat(line.tonnage) }, 0)
      }))
    }
}

